import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`29th July 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now specify decorators for all services in a `}<inlineCode parentName="p">{`Server`}</inlineCode>{` in a functional way. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1882"
          }}>{`#1882`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1900"
          }}>{`#1900`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
sb.service(...);
// After:
sb.decorator((delegate, ctx, req) -> {
    ...
    return delegate.serve(ctx, req);
});
// Before:
sb.decorator(delegate -> new SimpleServiceDecorator<HttpRequest, HttpResponse>(delegate) {
    @Override
    public HttpResponse serve(ServiceRequestContext ctx, HttpRequest req) throws Exception {
        ...
        return delegate().serve(ctx, req);
    }
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<inlineCode parentName="p">{`HealthCheckService`}</inlineCode>{` which has richer functionality than its predecessor `}<inlineCode parentName="p">{`HttpHealthCheckService`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1878"
          }}>{`#1878`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
sb.service("/internal/l7check",
           HealthCheckService.builder()
                             .checkers(...)
                             .healthyResponse(...)
                             .unhealthyResponse(...)
                             .longPolling(Durations.ofMinutes(1))
                             .updatable(true)
                             .build());
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`Long-polling query support`}
            <ul parentName="li">
              <li parentName="ul">{`You can wait until the server becomes healthy or unhealthy and get notified immediately.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Easy customization of healthy/unhealthy responses`}</li>
          <li parentName="ul">{`Easy customization of update request handler`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You now know exact host you are sending your request to when using Armeria client. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1917"
          }}>{`#1917`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`It was previously not possible when you specified an `}<inlineCode parentName="li">{`EndpointGroup`}</inlineCode>{` as the destination.`}</li>
          <li parentName="ul">{`Therefore, `}<inlineCode parentName="li">{`ClientRequestContext.endpoint()`}</inlineCode>{` may return `}<inlineCode parentName="li">{`null`}</inlineCode>{` since this release when Armeria failed to determine the destination host.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`ClientRequestContext.endpointSelector()`}</inlineCode>{` has been added so that you still know which `}<inlineCode parentName="li">{`EndpointGroup`}</inlineCode>{` you are connecting to.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`BraveClient`}</inlineCode>{` and `}<inlineCode parentName="p">{`BraveService`}</inlineCode>{` now accept `}<inlineCode parentName="p">{`HttpTracing`}</inlineCode>{` as well as `}<inlineCode parentName="p">{`Tracing`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1223"
          }}>{`#1223`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1906"
          }}>{`#1906`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Tracing tracing = ...;

// Server-side
ServerBuilder sb = new ServerBuilder();
//// Before
sb.service("/", myService.decorate(BraveService.newDecorator(tracing)));
//// After
HttpTracing httpTracing = HttpTracing.create(tracing);
sb.service("/", myService.decorate(BraveService.newDecorator(httpTracing)));

// Client-side
HttpClientBuilder cb = new HttpClientBuilder(...);
//// Before
cb.decorator(BraveClient.newDecorator(tracing, "myBackend"));
//// After
cb.decorator(BraveClient.newDecorator(httpTracing.clientOf("myBackend")));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now create a decorating `}<inlineCode parentName="p">{`Service`}</inlineCode>{` or `}<inlineCode parentName="p">{`Client`}</inlineCode>{` in a less verbose way by extending new `}<inlineCode parentName="p">{`SimpleDecorating*`}</inlineCode>{` classes. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1881"
          }}>{`#1881`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1925"
          }}>{`#1925`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`SimpleDecoratingHttpService extends SimpleDecoratingService<HttpRequest, HttpResponse>`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`SimpleDecoratingRpcService extends SimpleDecoratingService<RpcRequest, RpcResponse>`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`SimpleDecoratingHttpClient extends SimpleDecoratingClient<HttpRequest, HttpResponse>`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`SimpleDecoratingRpcClient extends SimpleDecoratingClient<RpcRequest, RpcResponse>`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`RpcService`}</inlineCode>{` has been added for your convenience. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1881"
          }}>{`#1881`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1925"
          }}>{`#1925`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`RpcService extends Service<RpcRequest, RpcResponse>`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvements",
        "aria-label": "improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvements`}</h2>
    <ul>
      <li parentName="ul">{`The output type of `}<inlineCode parentName="li">{`RequestLog`}</inlineCode>{` sanitizer functions has been relaxed to `}<inlineCode parentName="li">{`?`}</inlineCode>{` so that a user can write much simpler and more efficient sanitizer function. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1810"
        }}>{`#1810`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1879"
        }}>{`#1879`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before
Function<HttpHeaders, HttpHeaders> sanitizer = headers -> {
    return headers.toBuilder().removeAndThen("authorization").build();
};
// After
Function<HttpHeaders, Object> sanitizer = headers -> {
    // No need to construct a new HttpHeaders object.
    return headers.toBuilder().removeAndThen("authorization").toString();
};
// Even more efficiently
Function<HttpHeaders, Object> sanitizer = headers -> "sanitized!";
`}</code></pre>
      </li>
      <li parentName="ul">{`A glob path mapping is not translated into a regex path mapping when combined with path prefix anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1929"
        }}>{`#1929`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ByteBufHttpData.toInputStream()`}</inlineCode>{` does not perform extra memory copy anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1918"
        }}>{`#1918`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1919"
        }}>{`#1919`}</a></li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Armeria client now sends `}<inlineCode parentName="li">{`OPTIONS * HTTP/1.1`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`HEAD / HTTP/1.1`}</inlineCode>{`, so that the initial protocol upgrade does not trigger business logic when a server has business logic bound at `}<inlineCode parentName="li">{`/`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1895"
        }}>{`#1895`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1896"
        }}>{`#1896`}</a></li>
      <li parentName="ul">{`Response content is not sanitized twice anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1879"
        }}>{`#1879`}</a></li>
      <li parentName="ul">{`gRPC message compression is now enabled by default on the server side, so that the default behavior matches that of the upstream implementation. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1885"
        }}>{`#1885`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1889"
        }}>{`#1889`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`max-age`}</inlineCode>{` attribute in a `}<inlineCode parentName="li">{`set-cookie`}</inlineCode>{` header is now handled correctly when using Spring WebFlux. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1904"
        }}>{`#1904`}</a></li>
      <li parentName="ul">{`Fixed a bug where the base URL of the received request is set incorrectly when using Spring WebFlux. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1904"
        }}>{`#1904`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`HttpHealthCheckedEndpoint`}</inlineCode>{` does not leak connections and requests after `}<inlineCode parentName="li">{`close()`}</inlineCode>{` is called. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1899"
        }}>{`#1899`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`BraveClient`}</inlineCode>{` does not trigger `}<inlineCode parentName="li">{`RequestLogAvailabilityException`}</inlineCode>{` anymore when the request being traced has failed without sending any data. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1911"
        }}>{`#1911`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1912"
        }}>{`#1912`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`@ConsumesJson`}</inlineCode>{` annotation now accepts JSON documents with any charsets rather than only `}<inlineCode parentName="li">{`UTF-8`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1920"
        }}>{`#1920`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` raised by `}<inlineCode parentName="li">{`pushIfAbsent()`}</inlineCode>{` is now propagated properly to the `}<inlineCode parentName="li">{`CompletableFuture`}</inlineCode>{` created by `}<inlineCode parentName="li">{`RequestContext.makeContextAware()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1920"
        }}>{`#1920`}</a></li>
    </ul>
    <h2 {...{
      "id": "deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deprecations",
        "aria-label": "deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`HttpHealthCheckService`}</inlineCode>{` and `}<inlineCode parentName="li">{`SettableHttpHealthCheckService`}</inlineCode>{` have been deprecated in favor of `}<inlineCode parentName="li">{`HealthCheckService`}</inlineCode>{` and its builder. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1878"
        }}>{`#1878`}</a></li>
    </ul>
    <h2 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ClientRequestContext.endpoint()`}</inlineCode>{` may return `}<inlineCode parentName="li">{`null`}</inlineCode>{` since this release. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1917"
        }}>{`#1917`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Endpoint`}</inlineCode>{` returned by `}<inlineCode parentName="li">{`ClientRequestContext.endpoint()`}</inlineCode>{` now always refers to a single host, not an `}<inlineCode parentName="li">{`EndpointGroup`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1917"
        }}>{`#1917`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`ClientRequestContext.endpointSelector().group()`}</inlineCode>{` to retrieve the related `}<inlineCode parentName="li">{`EndpointGroup`}</inlineCode>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`gRPC 1.21.0 -> 1.22.1`}</li>
      <li parentName="ul">{`Netty 4.1.37 -> 4.1.38`}</li>
      <li parentName="ul">{`Tomcat 9.0.21 -> 9.0.22, 8.5.42 -> 8.5.43`}</li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'babjo', 'codefromthecrypt', 'hirakida', 'hyeonjames', 'ikhoon', 'imasahiro', 'kojilin', 'minwoox', 'trustin', 'woohhan']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      